























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Items } from '../../store';
import { Item } from '../../shared/entities';
import { log, logRaw } from '@/shared/util';
import { throttle } from 'lodash';

@Component({
  name: 'ItemElement',
  components: {
    // avoid error due to recursive use of TextElement
    // alternative solutions: https://stackoverflow.com/a/58875919/548955
    TextElement: () => import('./TextElement.vue'),
  }
})
export default class ItemElement extends Vue {
  @Prop(Object) item!: Item;
  @State items!: Items;
  @Getter itemCount!: number;
  flipped = false;
  visible = false;
  mediaPlaying = false;
  mediaProgress = 0;

  visibilityChanged(isVisible) {
    log("ItemElement.visibilityChanged", isVisible);
    this.visible = isVisible;
    if (!isVisible) {
      this.flipped = false;
      this.toggleAudio(false);
    }
  }

  // mounted() {
  //   this.toggleAudio(false);
  // }

  toggleAudio(play = !this.mediaPlaying) {
    if (!this.audio) return;
    logRaw("toggleAudio", play, this.mediaPlaying, this.mediaProgress);
    const updateAudioProgress = throttle(() => {
      logRaw("media prgress update", this.mediaProgress, this.audio.currentTime, this.audio.duration);
      this.mediaProgress = this.audio.currentTime / this.audio.duration;
    }, 1000);
    const ended = () => this.toggleAudio(false);
    if (play) {
      this.audio.play();
      this.audio.addEventListener("timeupdate", updateAudioProgress);
      this.audio.addEventListener("ended", ended);
    }
    else {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", updateAudioProgress);
      this.audio.removeEventListener("ended", ended);
      this.mediaProgress = this.audio.currentTime = 0;
    }
    this.mediaPlaying = play;
  }

  get itemIndex(): number {
    return this.items.indexOf(this.item.id);
  }

  get audio(): HTMLAudioElement {
    return this.$refs.audio as HTMLAudioElement;
  }

  // get mediaPlaying(): boolean {
  //   return !this.audio.paused;
  // }

  get url(): string | undefined {
    // TODO put the "silent.mp3" into a var of some sorts
    return this.flipped ? this.item.mediaUrl : '/assets/items/silent.mp3'; // load when flipping to the backside only
  }
}
