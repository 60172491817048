







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ProgressButton',
  components: {}
})
export default class ProgressButton extends Vue {
  @Prop({ type: Number, default: 0, required: true }) progress;
}
