










import { Component, Vue } from 'vue-property-decorator';
import book from '../../book';

@Component({
  name: 'FeedbackMode',
  components: {}
})
export default class FeedbackMode extends Vue {
  config = book.config;

  get stateString(): string {
    return JSON.stringify(this.$store.state, undefined, ' ');
  }
}
