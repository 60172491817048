






















import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { logJson } from "../../shared/util";
import { AddItem, Item } from "../../shared/entities";
import ItemElement from './ItemElement.vue';
import book from "../../book";

@Component({
  name: "AddItemElement",
  components: { ItemElement },
})
export default class AddItemElement extends Vue {
  @Prop(Object) element!: AddItem;
  @State items;
  @Action addItem;

  visibilityChanged(isVisible) {
    logJson("AddItemElement.visibilityChanged", this.element, isVisible);
    if (isVisible) {
      // wait two seconds before adding the item
      setTimeout(() => this.addItem({ item: this.element }), 2000);
    }
  }

  get item(): Item | undefined {
    return book.config.items.find((item) => item && item.id === this.element.id);
  }
}
