import { render, staticRenderFns } from "./Tester.vue?vue&type=template&id=88983bea&scoped=true&lang=pug&"
import script from "./Tester.vue?vue&type=script&lang=ts&"
export * from "./Tester.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88983bea",
  null
  
)

export default component.exports