

















import { Position } from "@/store";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Chapter } from "../../shared/entities";
import { warn } from "@/shared/util";
import { findIndex } from "lodash";

@Component({
  name: "Chapters",
  components: {},
})
export default class Chapters extends Vue {
  @Getter position!: Position;
  @Prop(Array) chapters!: Chapter[];
  @Action goto!: Function;
  @Action overlay!: Function;
  previous = 0;
  current = 0;

  created() {
    this.current = warn('Chapters.created: initial index', findIndex(this.chapters, chapter => chapter.id === this.position.chapter.id))!;
  }

  show(index = this.current) {
    this.current = index;
  }
}
