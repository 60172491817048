





















import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class'
import { Choice, Option } from '../../shared/entities';
import { Options as OptionsType} from '../../store';
import book from '../../book';

@Component({
  name: 'Options',
  components: {
  },
})
export default class Options extends Vue {
  @Action private setOption!: Function;
  @State options!: OptionsType;
  @Getter started!: boolean;
  @Action reset!: Function;
  config = book.config;
  confirm = false;

  choose(option: Option, choice: Choice) {
    this.setOption({ option, choice });
  }
}
