import { render, staticRenderFns } from "./ParagraphElement.vue?vue&type=template&id=7d6a4056&scoped=true&lang=pug&"
import script from "./ParagraphElement.vue?vue&type=script&lang=ts&"
export * from "./ParagraphElement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d6a4056",
  null
  
)

export default component.exports